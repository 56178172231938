import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import React, { useEffect } from "react"
import Modal from "react-modal"
import { Swiper, SwiperSlide } from "swiper/react"
import Layout from "../components/layout"
import ModernImg from "../components/ModernImg"
import SEO from "../components/seo"

Modal.setAppElement(`#___gatsby`)

const IndexPage = () => {
  const { t } = useTranslation()
  const [modalIsOpen, setModalIsOpen] = React.useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (!sessionStorage?.getItem("scamModalShown")) {
        setModalIsOpen(true)
        sessionStorage.setItem("scamModalShown", true)
      }
    }, 500)
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {
            zIndex: 1000,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            transition: "0.3s ease-in-out",
            maxWidth: 900,
            margin: "auto",
          },
        }}
      >
        {/* start of announcmenet */}
        <section className="pd-top-100 bg-grey">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section-title section-title-2 text-center">
                  <h2 className="title">Important Announcement</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center pd-bottom-100">
              <div className="col-lg-12">
                <h5 style={{ marginBottom: 30 }}>
                  Please be aware of a scam involving persons posing as ICH
                  officers or claiming to represent the company or company group
                  affiliates.
                </h5>
                <p>
                  ICH has recently learned of a scam involving persons posing as
                  company officers, or falsely claiming to represent the company
                  or company group affiliates, while soliciting investments on
                  behalf of the company.
                </p>
                <p>
                  The company, its group affiliates and officers have absolutely
                  no involvement with these fraudulent practices and do not make
                  such solicitations.
                </p>
                <p>
                  Please be aware of this scam, and if you receive SMS, email or
                  other solicitations from anyone posing as the company, its
                  group affiliates or company officers that appears even
                  remotely suspicious, contact us immediately.
                </p>
                <p>
                  Do not respond to these messages and emails or communicate
                  with the sender, and do not provide the sender with any
                  personal or financial information or deposit any money.
                </p>
                <p>
                  For inquiries concerning this matter, please contact us at{" "}
                  <a href="tel://+6568461800">(65) 6846 1800.</a>
                </p>
                <div style={{ textAlign: "center", marginTop: 30 }}>
                  <button
                    className="pink-button"
                    style={{ width: 200 }}
                    onClick={() => setModalIsOpen(false)}
                  >
                    I understand
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end of announcmenet */}
      </Modal>
      {/* <!-- Banner Area Start--> */}
      <section className="banner-area banner-slider-2">
        <div className="swiper-container">
          <Swiper slidesPerView={1}>
            <SwiperSlide>
              <ModernImg
                src={"/assets/img/banner/banner-image.jpg"}
                alt="qing hai"
                style={{ width: "100%" }}
              />
              <div
                className="slide-inner"
                style={{ top: 40, position: "absolute" }}
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-8">
                      <div
                        className="banner-inner banner-inner-2 text-center"
                        style={{ marginTop: 120 }}
                      >
                        <h2 className="text-white">
                          ICH - {t("investing-for-tomorrow")}
                        </h2>
                        {/* <h1 className="text-white">
                        Investing in a better tomorrow
                      </h1> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* <!-- Banner Area End --> */}
      <section className="pd-top-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title section-title-2 text-center">
                <h6 className="sub-title">{t("company")}</h6>
                <h2 className="title">{t("about-us")}</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center pd-bottom-100">
            <div className="col-lg-5">
              <ModernImg src={"/assets/img/csr-banner.jpg"} alt="about us" />
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-6 text-justify" style={{ fontSize: 17 }}>
              {t("about-us-text")}
              <div className="csr-home-footer">
                <Link className="csr-learn-more" to="/about-us/">
                  {t("learn-more")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="history-area-2 pd-top-100 pd-bottom-70"
        style={{ background: "url(/assets/img/units-bg.jpg)" }}
      >
        <div className="container-deprecated">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title section-title-2 style-white text-center">
                <h6 className="sub-title">{t("company")}</h6>
                <h2 className="title">ICH {t("business-units")}</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {/* <Link
              to="/business-units/private-equity"
              className="single-history-wrap single-history-wrap-2 media"
            >
              <div className="thumb">
                <i className="fas fa-hand-holding-usd"></i>
              </div>
              <div className="wrap-details align-self-center">
                <h6>{t("private-equity")}</h6>
              </div>
            </Link>
            <Link
              to="/business-units/direct-investments"
              className="single-history-wrap single-history-wrap-2 media"
            >
              <div className="thumb">
                <i className="fas fa-seedling"></i>
              </div>
              <div className="wrap-details align-self-center">
                <h6>{t("direct-investments")}</h6>
              </div>
            </Link>
            <Link
              to="/business-units/funds-of-funds"
              className="single-history-wrap single-history-wrap-2 media"
            >
              <div className="thumb">
                <i className="fas fa-coins"></i>
              </div>
              <div className="wrap-details align-self-center">
                <h6>{t("funds-of-funds")}</h6>
              </div>
            </Link> */}
            <a
              href="https://icham.sg"
              target="_blank"
              rel="noreferrer"
              className="single-history-wrap single-history-wrap-2 media"
            >
              <div className="thumb">
                <i className="fas fa-users"></i>
              </div>
              <div className="wrap-details align-self-center">
                <h6>{t("multi-family-office")}</h6>
              </div>
            </a>
            <a
              href="https://addx.co/en/"
              target="_blank"
              rel="noreferrer"
              className="single-history-wrap single-history-wrap-2 media"
            >
              <div className="thumb">
                <i className="fas fa-chart-area"></i>
              </div>
              <div className="wrap-details align-self-center">
                <h6>{t("ich-fintech")}</h6>
              </div>
            </a>
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: "#fbf9f9" }} className="pd-top-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title section-title-2 text-center">
                <h2 className="title">ICH {t("bursary")}</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center pd-bottom-100">
            <div className="col-lg-6 text-justify" style={{ fontSize: 17 }}>
              {t("csr-home-text")}
              <div className="csr-home-footer">
                <Link className="csr-learn-more" to="/csr/">
                  {t("learn-more")}
                </Link>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <ModernImg src={"/assets/img/csr-home.jpeg"} alt="about us" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
